import { MarketingNavbarComponent } from "../Components/navbar";
import { FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineEdit } from "react-icons/md";

const News = () => {
  const MastersModal = Masters.News;
  const [formData, setFormData] = useState({});
  const [popupType, setpopupType] = useState("");
  const [message, setMessage] = useState(null);
  const [readOnly, setReadOnly] = useState(true);

  const GetData = async () => {
    try {
      const response = await axiosConfig.get(`/catlog/Scrolling_News/`);
      console.log(response.data.results[0]);
      setFormData(response.data.results[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = () => {
    setReadOnly(false);
  };
  const formSubmit = async (Data) => {
    try {
      if (Data.id) {
        const response = await axiosConfig.patch(
          `/catlog/Scrolling_News/${Data.id}/`,
          Data
        );
      } else {
        const response = await axiosConfig.post(
          `/catlog/Scrolling_News/`,
          Data
        );
      }
      setMessage("News added successfully");
      setpopupType("success");
      setReadOnly(true);
    } catch (error) {
      console.log("Error setting the site data : ", error);
      setpopupType("error");
    }
  };
  useEffect(() => {
    GetData();
  }, []);
  return (
    <>
      <div className="child-sidenav">
        <MarketingNavbarComponent />
        <div className="childnav-content">
          <FormParser
            modelObject={MastersModal}
            formData={formData}
            formSubmit={formSubmit}
            buttonTitle="Save"
            readOnly={readOnly}
            setReadOnly={setReadOnly}
          />
          {readOnly && (
            <button
              className={`btn btn-primary d-flex align-items-center`}
              onClick={() => handleEdit()}
            >
              <div>Edit</div>
              <MdOutlineEdit className="ms-5" />
            </button>
          )}
        </div>
      </div>
      {popupType != "" && (
        <PopupModal
          popupType={popupType}
          setpopupType={setpopupType}
          Navigation=""
          Message={message}
        />
      )}
    </>
  );
};
export { News };
