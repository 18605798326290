import React, { useState } from "react";
import './ToggleSwitch.css'

const ToggleSwitch = ({ row, checked, handleToggle }) => {
    return (
        <div>
            <input
                className="toggle-switch"
                type="checkbox"
                name="checkbox"
                id={`toggle ${row.id}`}
                checked={checked}
                onChange={(e) => handleToggle(e, row)}
            />
            <label for={`toggle ${row.id}`} className="switch"></label>
        </div>
    )
}

export default ToggleSwitch