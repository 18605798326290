import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import DataTable from "react-data-table-component";
import Masters from "../Components/masters.json";
import { DeletePopupModal, PopupModal, FormParser } from "../Components";
import { BiSearch } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import { FaHandHoldingHeart } from "react-icons/fa";
import useDebouncedValue from "../Utils/Debounce";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { CMSNavbarComponent } from "../Components/navbar";
const DisplayCategorie = () => {
  const [Loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [LoadingButton, setLoadingButton] = useState();
  const [Data, SetData] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [popupType, setpopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const MastersModal = Masters.DisplayCategorie;
  const [deletePopupType, setDeletepopupType] = useState("");
  const [Error, SetError] = useState();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [search, SetSearch] = useState({
    search: "",
  });
  const debouncedSearchTerm = useDebouncedValue(search.search, 500);
  const HandleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    SetSearch({ ...search, [name]: value });
  };
  const displayCategory_columns = [
    {
      name: "ID",
      cell: (row, index) => index + 1,
    },
    {
      name: "name",
      cell: (row) =>
        row.subcategory ? row.subcategory.name : "no subcategory",
      sortable: true,
    },
    {
      name: "Status",
      selector: "staus",
      cell: (row) => (row.staus ? "Active" : "InActive"),
    },
    {
      name: "Order By",
      selector: "orderby",
      sortable: true,
    },
 
    {
      name: "ACTIONS",
      selector: (row) => {
        const HandleEdit = async () => {
          setShow(true);
          categoryedit(row.id);
          // handleShow(row.id);
          setModalTitle("Edit display Categories");
        };
        const DeleteAlert = () => {
          SetRowId(row.id);
          SetAlertDeletePopupType("success");
        };
        const CancelAlerrt = () => {
          SetAlertDeletePopupType("error");
        };
        const HandleDelete = async (id) => {
          await axiosConfig
            .delete(`/catlog/displaycategories/${id}/`)
            .then(() => {
              SetAlertDeletePopupType("error");
              setDeletepopupType("success");
              GetData();
            })
            .catch((error) => {
              setDeletepopupType("error");
              SetAlertDeletePopupType("error");
              console.log(error);
            });
        };
        const handleRevive = async () => {
          try {
            const response = await axiosConfig.post(
              `/catlog/displaycategories/${row.id}/revive/`
            );
            setpopupType("success");
            setMessage("display category revived successfully");
            GetData();
          } catch (error) {
            console.log("Error While Reviving", error);
          }
        };
        const categoryedit = (id) => {
          axiosConfig
            .get(`/catlog/displaycategories/${id}/`)
            .then((res) => {
              console.log(res.data, "edit");
              setFormData({
                ...res.data,
                subcategory: res.data.subcategory.id,
              });
              setShow(true);
              setMessage("Record Updated Successfully");
            })
            .catch((error) => {
              console.log(error);
            });
        };
       return (
          <>
            {!row.is_suspended ? (
              <div className="row">
                <div className="col-4">
                  <MdOutlineEdit
                    className="dashboard-tabe-data-actions"
                    onClick={HandleEdit}
                  />
                </div>
                <div className="col-4">
                  <MdDeleteOutline
                    className="dashboard-tabe-data-actions"
                    onClick={DeleteAlert}
                  />
                </div>
              </div>
            ) : (
              <>
                <FaHandHoldingHeart
                  className="dashboard-tabe-data-actions"
                  onClick={handleRevive}
                />
              </>
            )}
            {AlertDeletePopupType === "success" && (
              <AlertDeletePopupModal
                AlertDeletePopupType={AlertDeletePopupType}
                HandleDelete={HandleDelete}
                CancelAlerrt={CancelAlerrt}
                row={RowId}
              />
            )}
          </>
        );
      },
    },
  ];
  const GetData = async () => {
    try {
      let response = await axiosConfig.get(
        `/catlog/displaycategories/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`
      );
      console.log(response.data.results);
      SetData(response.data.results);
      setLoading(false);
      setTotalRows(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetData();
  }, [currentPage, RowPerPage, debouncedSearchTerm]);
  const formSubmit = async (FormData) => {
    console.log(FormData, "formdata");
    setLoadingButton(true);
    if (FormData.id) {
      await axiosConfig
        .patch(`/catlog/displaycategories/${FormData.id}/`, FormData)
        .then((res) => {
          console.log(res);
          setpopupType("success");
          GetData();
          setShow(false);
        })
        .catch((error) => {
          console.log(error);
          let error_message = error.response.data;
          Object.values(error_message)[0] &&
            SetError(Object.values(error_message)[0]);
          setpopupType("error");
        });
      setLoadingButton(false);
    } else {
      await axiosConfig
        .post("/catlog/displaycategories/", FormData)
        .then((res) => {
          console.log(res);
          setpopupType("success");
          GetData();
          setShow(false);
        })
        .catch((error) => {
          console.log(error);
          let error_message = error.response.data;
          Object.values(error_message)[0] &&
            SetError(Object.values(error_message)[0]);
          setpopupType(error);
        });
      setLoadingButton(false);
    }
  };
  const handleClose = () => {
    setShow(false);
    SetError("");
    setpopupType("");
    setMessage("");
    setLoadingButton(false);
  };
  const handleSort = (column, direction) => {
    console.log(column.selector, direction);
    axiosConfig
      .get(
        `/catlog/displaycategories/?page=${currentPage}&page_size=${RowPerPage}&ordering=${
          direction === "asc" ? column.selector : -column.selector
        }`
      )
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentPage(1);
  };
  const handleShow = () => {
    setShow(true);
    setFormData({
      subcategory: "",
      staus: "",
      orderby: "",
    });
    setModalTitle("Add DisplayCategorie");
  };
  const tableCustomStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        color: "#5C5C5C",
        justifyContent: "center",
        color: "rgb(33,37,41)",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "28px",
      },
    },
  };
 
  return (
    <>
      <div className="child-sidenav">
        <CMSNavbarComponent />
        <div className="childnav-content">
          <div className="d-flex align-items-center">
            <div className="col-4 input-cat-with-icon">
              <span className="icon-product">
                <BiSearch />
              </span>
              <input
                type="text"
                className="input-control"
                placeholder="Search"
                name="search"
                value={search.search}
                onChange={HandleSearch}
              />
            </div>
            <div className="cat-button">
              <div className="btn" onClick={handleShow}>
                + Add New
              </div>
            </div>
          </div>
          <div className="table-responsive marginTop">
            <DataTable
              columns={displayCategory_columns}
              data={Data}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={RowPerPage}
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
              paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
              onChangeRowsPerPage={(RowsPerPageChange) =>
                setRowPerPage(RowsPerPageChange)
              }
              onSort={handleSort}
              fixedHeader
              subHeader
              customStyles={tableCustomStyles}
              progressPending={Loading}
              progressComponent={
                <div className="loader-container">
                  <img
                    src={require("../Components/FormParser/images/loader2.gif")}
                    alt="Loading..."
                  />
                </div>
              }
            />
          </div>
          <Modal
            size="xl"
            show={show}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                {modalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormParser
                modelObject={MastersModal}
                formData={formData}
                formSubmit={formSubmit}
                error={Error}
                buttonTitle="Save"
                LoadingButton={LoadingButton}
              />
            </Modal.Body>
          </Modal>
          {popupType !== "" && (
            <PopupModal
              setpopupType={setpopupType}
              popupType={popupType}
              Navigation=""
              Message={message}
              error={Error}
              setMessage={setMessage}
            />
          )}
          {deletePopupType !== "" && (
            <DeletePopupModal
              setDeletepopupType={setDeletepopupType}
              deletePopupType={deletePopupType}
            />
          )}
        </div>
      </div>
    </>
  );
};
export { DisplayCategorie };