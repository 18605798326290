import React, { useEffect, useState } from "react";
import { SettingNavbarComponent } from "../Components/navbar";
import { FormParser } from "../Components";
import Masters from "../Components/masters.json";
import { MdOutlineEdit } from "react-icons/md";
import axiosConfig from "../Service/axiosConfig";
import { PopupModal } from "../Components";

const Policies = () => {
    const MastersModal = Masters.Policies;
    const [readOnly, setReadOnly] = useState(true)
    const [formData, setFormData] = useState({})
    const [message, setMessage] = useState(null)
    const [popupType, setpopupType] = useState("")
    const GetPolicies = async () => {
        try {
            const responce = await axiosConfig.get(`/catlog/policies/`);
            console.log(responce.data.results)
            setFormData(responce.data.results[0])
        } catch (error) {
            console.log(error)
        }
    }

    const formSubmit = async (Data) => {
        console.log(Data)
        try {
            axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
            if (Data.id) {
                const response = await axiosConfig.patch(`/catlog/policies/${Data.id}/`, Data)
                setMessage("Policies updated successfully")
                setpopupType("success")
                setReadOnly(true)
            } else {
                const response = await axiosConfig.post(`/catlog/policies/`, Data)
                console.log("created Policies successfully : ", response)
                setMessage("Policies saved successfully")
                setpopupType("success")
                setReadOnly(true)
            }
        } catch (error) {
            console.log(error)
            setpopupType("error")
            setMessage("Error updating Policies")
            setReadOnly(true)
        }
    }
    useEffect(() => {
        GetPolicies()
    }, [])
    return (
        <div className="child-sidenav">
            <SettingNavbarComponent />
            <div className="childnav-content">
                <FormParser modelObject={MastersModal} formData={formData} readOnly={readOnly} buttonTitle="Save" formSubmit={formSubmit} />
                {readOnly &&
                    <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly(false)}>
                        <div>Edit</div>
                        <MdOutlineEdit className="ms-5" />
                    </button>
                }
                {popupType !== "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
                }
            </div>
        </div >
    )
}

export { Policies }