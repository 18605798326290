import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
const Logout = () => {
  const Navigate = useNavigate();
  const Auth = localStorage.removeItem("token")
  localStorage.clear();
  if (Auth) {
    return Navigate("/")
  }
}
export default Logout;