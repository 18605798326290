import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { NavLink, useNavigate,useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { FaHandHoldingHeart } from "react-icons/fa"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { Button, Modal } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi"
import Select from 'react-select';
import { NavbarComponent } from "../Components/navbar/NavComponent";
import Masters from "../Components/masters.json";
import useDebouncedValue from "../Utils/Debounce";
import { useURLParams } from '../Components/UrlUtils/useURLParams';
const Subcategories = () => {
    const { setParam, getParam } = useURLParams();
    const MastersModal = Masters.SubCategories
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = useState(false)
    const handleClose = () => {
        setShow(false)
        SetError("")
        setpopupType("")
    }
    const [message, setMessage] = useState('')
    const [Error, SetError] = useState("")
    const [modalTitle, setModalTitle] = useState("Add New Item");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(getParam('category')||"");
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                // let allCategories = [];
                // let nextPage = "catlog/categories/";

                // // Loop until nextPage is null
                // while (nextPage !== null) {
                //     const response = await axiosConfig.get(nextPage);
                //     const categories = response.data.results.map(category => ({
                //         value: category.id,
                //         label: category.name
                //     }));
                //     allCategories = allCategories.concat(categories);
                //     nextPage = response.data.next; // nextPage could be null, no need for the || null
                // }

                // console.log("All categories:", allCategories);
                // setCategoryOptions(allCategories);
                let response = await axiosConfig.get("catlog/categories/?page_size=50&is_suspended=false");
                setCategoryOptions(response.data.results.map(category => ({
                    value: category.id,
                    label: category.name
                })))
            } catch (error) {
                console.error("Error fetching categories:", error);
                setCategoryOptions([]);
            }
        };


        fetchCategories();
    }, []);



    const handleCategoryFilterChange = (e) => {
        console.log(e)
        setSelectedCategoryId(e.value);
        setParam('category', e.value);
    };

    const handleShow = () => {
        setShow(true)
        setFormData({
            name: '',
            description: '',
            slug: '',
            image: '',
            category: '',
            meta_title: '',
            meta_description: '',
            meta_keyword: '',
            sort: ''
        });
        setcatid(null);
        setModalTitle("Add New Item");
    };
    const [catid, setcatid] = useState()
    const [search, SetSearch] = useState({
        search:getParam('search') || ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true);
        try {
            let url = `catlog/sub-categories/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`;
            if (selectedCategoryId) {
                url += `&category=${selectedCategoryId}`;
            }
            const response = await axiosConfig.get(url);
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };


    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        setParam('search', value);
    }
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`catlog/sub-categories/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        slug: '',
        image: '',
        category: '',
        meta_title: '',
        meta_description: '',
        meta_keyword: '',
        sort: ''
    });

    const catedit = (id) => {
        axiosConfig
            .get(`catlog/sub-categories/${id}/`)
            .then((res) => {
                console.log(res.data);
                setFormData({
                    ...res.data,
                    category: res.data.category.id,
                });
                setShow(true)
                setcatid(id);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, selectedCategoryId, debouncedSearchTerm]);


    console.log(formData)
    const formSubmit = (formData, setFieldErrors) => {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("slug", formData.slug);
        formDataToSend.append("sort", formData.sort);
        formDataToSend.append("image", formData.image.id);
        formDataToSend.append("category", formData.category)
        formDataToSend.append("meta_title", formData.meta_title);
        formDataToSend.append("meta_description", formData.meta_description);
        formDataToSend.append("meta_keyword", formData.meta_keyword);
        console.log(formDataToSend, 'category')
        axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
        if (formData.id) {
            axiosConfig
                .patch(`catlog/sub-categories/${formData.id}/`, formDataToSend)
                .then((res) => {
                    console.log(res);
                    setMessage("SubCategory Updated Successfully")
                    setpopupType("success");
                    GetData();
                    setShow(false)
                    SetError("")
                })
                .catch((error) => {
                    let errorData = {}
                    console.log(error)
                    if (error.response.data.error) {
                        errorData.error = error.response.data.error
                        SetError(error.response.data.error)
                    }
                    if (error.response.data.error === "Slug is required.") {
                        errorData.error = "Slug is required."
                        SetError("Slug is required.")
                    }
                    setpopupType("error");
                });
        } else {
            axiosConfig
                .post("catlog/sub-categories/", formDataToSend)
                .then((res) => {
                    console.log(res);
                    setMessage("SubCategory Created Successfully")
                    setpopupType("success");
                    GetData();
                    setShow(false)
                    SetError("")
                })
                .catch((error) => {
                    let errorData = {}
                    console.log(error)
                    if (error.response.data.error === "A sub-categories with this slug already exists.") {
                        errorData.error = "A sub-categories with this slug already exists."
                        SetError("A sub-categories with this slug already exists.")
                    }
                    if (error.response.data.error === "Slug is required.") {
                        errorData.error = "Slug is required."
                        SetError("Slug is required.")
                    }
                    setpopupType("error");
                });
        }
    };

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: row => row.name,
            sortable: true
        },
        {
            name: "image",
            selector: row => <>
                <img className="mng-prod-img" src={row.image && row.image.image} alt={row.image && row.image.image} />
            </>,
            sortable: true

        },
        {
            name: "category_name",
            selector: "category_name",
            cell: row => row.category_name,
            sortable: true
        },
        {
            name: "slug",
            selector: "slug",
            cell: row => row.slug,
            sortable: true
        },
        {
            name: "sort",
            selector: "sort",
            cell: row => row.sort,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, formData, 'iddddd')
                    catedit(row.id)
                    setModalTitle("Edit Item");
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`catlog/sub-categories/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`catlog/sub-categories/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Sub-category revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {!row.is_suspended ?
                            <div className="row" >
                                <div className="col-4">
                                    <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>
                                <div className="col-4">
                                    <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                            </div>
                            :
                            <>
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]  
    const clearSelection = () => {
        setSelectedCategoryId('');
        setParam('category', ''); // Reset or remove the URL parameter
      };
    
      // Find the selected option based on selectedCategoryId
      const selectedOption = categoryOptions&&categoryOptions.find(option => option.value === parseFloat(selectedCategoryId)) || null;
    return (
        <>
            <div className="child-sidenav">
                <NavbarComponent />
                <div className="childnav-content">
                    <div className="cat-button">
                        <div className="btn" onClick={handleShow} >
                            + Add New
                        </div>
                    </div>

                    <div className="row d-flex justify-content-between">
                        <div className="col-md-4 sub-filter">
                            <Select
                                options={categoryOptions}
                                placeholder={selectedCategoryId ? "" : "Select Category"}
                                value={selectedOption} // Set to null if no option is selected
                                onChange={(selectedOption) => {
                                    if (!selectedOption) {
                                      clearSelection(); // Clear selection if nothing is selected
                                    } else {
                                      handleCategoryFilterChange(selectedOption); // Handle change otherwise
                                    }
                                  }}
                                isClearable
                                required
                            />
                        </div>
                        <div className="col-md-4 input-subcat-with-icon">
                            <span className="icon-product"><BiSearch /></span>
                            <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                        </div>
                    </div>
                    <div className="table-responsive marginTop">
                        <DataTable
                            columns={columns}
                            data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                </div>
            </div>
            <Modal
                size="xl"
                show={show}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle='Save' />
                </Modal.Body>
            </Modal>

            {popupType != "" &&
                <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="/dashboard/masters/subcategories" Message={message} error={Error} />
            }
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
        </>
    )
}
export { Subcategories }