import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BsPCircle } from "react-icons/bs"
import { MdOutlineInventory2, MdOutlineBrandingWatermark, MdOutlineCategory } from "react-icons/md"
import {TbCategory} from "react-icons/tb"
const NavbarComponent = () => {
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/masters/categories" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineCategory size={24} className="childnav-icon" />
                <p className="childnav-name">Categories</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/subcategories" className="categorie-subtitle" ><div className="child-title">
                <TbCategory size={24} className="childnav-icon" />
                <p className="childnav-name">Sub Categories</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/products" className="categorie-subtitle" ><div className="child-title">
                <BsPCircle size={24} className="childnav-icon" />
                <p className="childnav-name">Products</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/inventory" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineInventory2 size={24} className="childnav-icon" />
                <p className="childnav-name">Inventory</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/brand" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineBrandingWatermark size={24} className="childnav-icon" />
                <p className="childnav-name">Brand / Manufacturers</p>
            </div></NavLink>
            {/* <NavLink to="/dashboard/masters/coupons" className="categorie-subtitle" >Coupons</NavLink>
        <NavLink to="/dashboard/masters/manageproducts" className="categorie-subtitle" >Manage Products</NavLink>
        <NavLink to="/dashboard/masters/banners" className="categorie-subtitle" >Banners</NavLink>
        <NavLink to="/dashboard/masters/brand" className="categorie-subtitle" >Brand</NavLink>
         */}
        </div>
    )
}

export {NavbarComponent} 