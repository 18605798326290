import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  MdOutlineViewCarousel,
  MdOutlineImage,
  MdOutlineCollections,
} from "react-icons/md";
import { BsCardText, BsMoonStars } from "react-icons/bs";
import { BiImages, BiCategory } from "react-icons/bi";
import { TbInfoTriangle } from "react-icons/tb";
import { AiOutlineHome } from "react-icons/ai";
import { TbCategoryPlus } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { LiaFileInvoiceSolid } from "react-icons/lia";

const CMSNavbarComponent = ({ handleShow }) => {
  return (
    <div className="categorie-header">
      <NavLink
        to="/dashboard/masters/homedesign"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <AiOutlineHome size={24} className="childnav-icon" />
          <p className="childnav-name">Homepage Design</p>
        </div>
      </NavLink>
      <NavLink className="categorie-subtitle">
        <div className="child-title" onClick={handleShow}>
          <BsMoonStars size={24} className="childnav-icon" />
          <p className="childnav-name">Theme</p>
        </div>
      </NavLink>
      <NavLink to="/dashboard/cms/old-banner" className="categorie-subtitle">
        <div className="child-title">
          <BsMoonStars size={24} className="childnav-icon" />
          <p className="childnav-name">Old Banner</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/cms/display-categorie"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <BiCategory size={24} className="childnav-icon" />
          <p className="childnav-name">Display Categories</p>
        </div>
      </NavLink>
      <NavLink to="/dashboard/cms/shop-deals" className="categorie-subtitle">
        <div className="child-title">
          <BiCategory size={24} className="childnav-icon" />
          <p className="childnav-name">Shop Deals</p>
        </div>
      </NavLink>
      <NavLink to="/dashboard/cms/about-us" className="categorie-subtitle">
        <div className="child-title">
          <TbInfoTriangle size={24} className="childnav-icon" />
          <p className="childnav-name">About Us</p>
        </div>
      </NavLink>
    </div>
  );
};

export { CMSNavbarComponent };
