import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import { NavLink } from "react-router-dom";

const AddStore = () => {
    const MastersModal = Masters.AddStore
    const [popupType, setpopupType] = useState("")
    const [Error, SetError] = useState("")
    const formSubmit = (Data) => {
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.post(`/stores/stores/`, Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            // if(ErrorData[0].code[0]==="priority with this code already exists."){
            //     SetError(" code already exists.")
            // }
            // if(ErrorData[0].name[0]==="priority with this name already exists."){
            //     SetError("Tittle already Exists")
            // }
            SetError(error.response.data.detail)
            setpopupType("error")
        })
    }

    useEffect(() => {
    }, [])
    return (
        <>
            <div className="dashboard-header">
                <h5 className="dashboard-subtitle">Add Store</h5>
            </div>
            <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} />
            {popupType != "" &&
                <PopupModal popupType={popupType} Navigation="/dashboard/admin/profile" />
            }
        </>
    )
}

export { AddStore }