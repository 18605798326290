import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Masters from "../masters.json"
import { FormParser } from '../FormParser/FormParser';
const Settingsmodal = ({ show,setShow}) => {
  const handleClose = () => setShow(false);
  const MastersModal = Masters.Settings
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FormParser modelObject={MastersModal}/>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Settingsmodal;
