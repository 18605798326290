import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi";
import { BsCurrencyRupee, BsPercent } from "react-icons/bs";
import { IoPricetagOutline } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import Select from 'react-select';
import axiosConfig from "../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa"
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import useDebouncedValue from "../Utils/Debounce";
const Coupon = () => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [show, setShow] = useState(false)
    const [modalTitle, setModalTitle] = useState("Add New Coupon");
    const [search, SetSearch] = useState({
        search: ""
    });
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    let cuponecodeList = []
    const handleClose = () => {
        setShow(false)
        SetError("")
        setpopupType("")
        setValidateError({})
        setSelectedValue(null)
        setActive("Flat")
        setFormData(
            {
                coupon_code: '',
                coupon_name: '',
                discount_type: "Flat",
                discount: 0,
                minimum_subtotal: '',
                amount: '',
                product: null,
                category: null,
                buy: 2,
                get: 1,
                now_only: 0,
                valid_from: getCurrentDate(new Date()),
                valid_to: getCurrentDate(new Date()),
                apply_to: '',
                apply_once_per_order: true,
                apply_to_each_eligible_item_in_the_order: false,
                limit_the_total_number_of_uses_for_this_coupon: false,
                limit_to_one_use_per_customer: false,
                use_count: ""
            }
        )
    };
    const [Active, setActive] = useState('Flat');
    const [popupType, setpopupType] = useState("");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Error, SetError] = useState("");
    const [message, setMessage] = useState('')
    const [disableValidTo, setDisableValidTo] = useState(true);
    const options = [
        { value: 'All_Products', label: 'All products' },
        { value: 'Specific_Product', label: 'Specific Product' },
        { value: 'Specific_Category', label: 'Specific Category' },
        { value: 'Minimum_Order_Subtotal', label: 'Minimum order subtotal' },
    ]
    const options1 = [
        { value: 'All_Orders', label: 'All orders' },
        { value: 'Minimum_Order_Subtotal', label: 'Minimum order subtotal' },
    ]
    const options2 = [
        { value: 'All_Products', label: 'All products' },
        { value: 'Specific_Product', label: 'Specific Product' },
        { value: 'Specific_Category', label: 'Specific Category' },
    ]
    const options3 = [
        { value: 'All_Product', label: 'All products' },
        { value: 'Specific_Product', label: 'Specific Product' },
        { value: 'Specific_Category', label: 'Specific Category' },
    ]
    function getCurrentDate(date) {
        const today = date;
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based, so we add 1
        const day = today.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const [formData, setFormData] = useState({
        coupon_code: '',
        coupon_name: '',
        discount_type: "Flat",
        discount: 0,
        minimum_subtotal: '',
        amount: '',
        product: null,
        category: null,
        buy: 2,
        get: 1,
        now_only: 0,
        valid_from: getCurrentDate(new Date()),
        valid_to: getCurrentDate(new Date()),
        apply_to: '',
        apply_once_per_order: true,
        apply_to_each_eligible_item_in_the_order: false,
        limit_the_total_number_of_uses_for_this_coupon: false,
        limit_to_one_use_per_customer: false,
        use_count: ""
    });
    console.log(formData)
    const handleShow = () => {
        setShow(true);
        setCouponid(null);
        setModalTitle("Add New Coupon");
    };
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    };
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData };
        const updatedValidationErrors = { ...validationErrors };
        if (name === 'valid_to') {
            if (formData.valid_from >= value) {
                SetError('Valid To date must be greater than Valid From date');
                return false;
            } else {
                updatedFormData[name] = value;
                SetError('');
            }
        } else {
            updatedFormData[name] = value;
        }

        setFormData(updatedFormData);
        setValidateError(updatedValidationErrors);
    };
    const handleRadio = (e) => {
        const { name, checked } = e.target;
        console.log(name, checked); // Check if the event handler is triggered correctly
        if (name === "apply_to_each_eligible_item_in_the_order") {
            setFormData({ ...formData, [name]: formData[name] === false ? true : false, "apply_once_per_order": false });
        }
        if (name === "apply_once_per_order") {
            setFormData({ ...formData, [name]: formData[name] === false ? true : false, "apply_to_each_eligible_item_in_the_order": false });
        }
        if (name === "limit_the_total_number_of_uses_for_this_coupon") {
            setFormData({ ...formData, [name]: formData[name] === false ? true : false, "limit_to_one_use_per_customer": false, use_count: checked === false ? "" : formData.number_of_uses })
        }
        if (name === "limit_to_one_use_per_customer") {
            setFormData({ ...formData, [name]: formData[name] === false ? true : false, "limit_the_total_number_of_uses_for_this_coupon": false, use_count: "" })
        }

    }
    const HandleDefaultValue = () => {
        setFormData({ ...formData, ["coupon_name"]: formData.coupon_code });

    }
    const handleTypeChange = (e, name, value) => {
        setActive(value)
        setFormData({ ...formData, [name]: value });
        console.log("disc_type", [name], value)
    };
    const handleSelectChange = (ApplyOption, name) => {
        setFormData({
            ...formData,
            [name]: ApplyOption.value
        });
        console.log("applyoptio", ApplyOption.value)
        console.log("apply-to", ['name'], ApplyOption.value)
        setSelectedValue(ApplyOption.value);
    };


    const HandleDropdownChange = (selectedOption, name) => {
        console.log(selectedOption)
        setFormData({
            ...formData,
            [name]: selectedOption.value
        });
    };
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setDisableValidTo(isChecked);
    };
    let validationErrors = {};
    const [ValidateError, setValidateError] = useState({})
    const formSubmit = (id) => {
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (!formData.coupon_code) {
            validationErrors.coupon_code = "Coupon Code is a required field";
        }
        if (!formData.coupon_name) {
            validationErrors.coupon_name = "Coupon Name is a required field";
        }
        if (!formData.apply_to) {
            validationErrors.apply_to = "Apply To is a required field";
        }
        if (isNaN(formData.discount) || formData.discount === "") {
            validationErrors.discount = "Discount must be a non-empty number";
        }
        if (isNaN(formData.discount) || formData.discount === "") {
            validationErrors.discount = "Discount must be a non-empty number";
        }
        if (isNaN(formData.buy) || formData.buy === "") {
            validationErrors.buy = "Buy must be a non-empty number";
        }
        if (isNaN(formData.get) || formData.get === "") {
            validationErrors.get = "get must be a non-empty number";
        }
        if (isNaN(formData.now_only) || formData.now_only === "") {
            validationErrors.now_only = "now_only must be a non-empty number";
        }
        if (Object.keys(validationErrors).length > 0) {
            setValidateError(validationErrors)
        }
        else {
            if (id) {
                axiosConfig.patch(`/catlog/coupons/${id}/`, formData).then(res => {
                    console.log(res);
                    setpopupType("success");
                    GetData();
                    setShow(false);
                    setSelectedValue("")
                    setFormData(
                        {
                            coupon_code: '',
                            coupon_name: '',
                            discount_type: "Flat",
                            discount: 0,
                            minimum_subtotal: '',
                            amount: '',
                            product: null,
                            category: null,
                            buy: 2,
                            get: 1,
                            now_only: 0,
                            valid_from: getCurrentDate(new Date()),
                            valid_to: getCurrentDate(new Date()),
                            apply_to: '',
                            apply_once_per_order: true,
                            apply_to_each_eligible_item_in_the_order: false,
                            limit_the_total_number_of_uses_for_this_coupon: false,
                            limit_to_one_use_per_customer: false,
                            use_count: ""
                        })
                    SetError("");
                }).catch(error => {
                    console.log(error);
                    setpopupType("error");
                });
            } else {
                axiosConfig.post("/catlog/coupons/", formData).then(res => {
                    console.log(res);
                    setpopupType("success");
                    GetData();
                    setShow(false);
                    setSelectedValue("")
                    setFormData(
                        {
                            coupon_code: '',
                            coupon_name: '',
                            discount_type: "Flat",
                            discount: 0,
                            minimum_subtotal: '',
                            amount: '',
                            product: null,
                            category: null,
                            buy: 2,
                            get: 1,
                            now_only: 0,
                            valid_from: getCurrentDate(new Date()),
                            valid_to: getCurrentDate(new Date()),
                            apply_to: '',
                            apply_once_per_order: true,
                            apply_to_each_eligible_item_in_the_order: false,
                            limit_the_total_number_of_uses_for_this_coupon: false,
                            limit_to_one_use_per_customer: false,
                            use_count: ""
                        })
                    SetError("");
                }).catch(error => {
                    console.log(error.response);
                    if (error.response?.data?.coupon_code?.[0]) {
                        validationErrors.coupon_code = error.response?.data?.coupon_code?.[0]
                    }
                    setpopupType("error");
                });
            }
        }

    };
    const [couponid, setCouponid] = useState()
    const couponedit = (id) => {
        axiosConfig.get(`/catlog/coupons/${id}/`)
            .then(res => {
                console.log(res)
                setFormData(res.data)
                setActive(res.data.discount_type)
                setCouponid(id);
                setShow(true)
                setSelectedValue(res.data.apply_to)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const fetchCategories = async () => {
        let categorieData = []
        if (localStorage.getItem("all_categories")) {
            let data = JSON.parse(localStorage.getItem("all_categories"))
            data.forEach(res => {
                categorieData.push({ value: res.id, label: res.name });
            });

            setCategoryOptions(categorieData);
        }
        else {
            try {
                const response = await axiosConfig.get(`catlog/categories/?page_size=500`);
                localStorage.setItem("all_categories", JSON.stringify(response.data.results))
                const categories = response.data.results
                const simplifiedCategories = categories.map(category => ({
                    value: category.id,
                    label: category.name,
                }));
                setProductOptions(simplifiedCategories);
            }
            catch (error) {
                console.error("Error fetching categories:", error);
                setCategoryOptions([]);
            }
        }
    }
    const fetchProducts = async () => {
        let ProductData = []
        if (localStorage.getItem("All_Products")) {
            let data = JSON.parse(localStorage.getItem("All_Products"));
            data.forEach(res => {
                ProductData.push({ value: res.id, label: res.name });
            });

            setProductOptions(ProductData);
        }
        else {
            try {
                const response = await axiosConfig.get(`/catlog/products/?page_size=3000`);
                localStorage.setItem("All_Products", JSON.stringify(response.data.results))
                setProductOptions(response.data.results);
                const Product = response.data.results
                const simplifiedProducts = Product.map(Product => ({
                    value: Product.id,
                    label: Product.name,
                }));
                setProductOptions(simplifiedProducts);
            }
            catch (error) {
                console.error("Error fetching categories:", error);
                setProductOptions([]);
            }
        }
    };
    console.log(productOptions, categoryOptions)
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/coupons/?page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    };
    useEffect(() => {
        const getSearchResults = async () => {
            axiosConfig.get(`/catlog/coupons/?search=${debouncedSearchTerm}`).then(res => {
                SetData(res.data.results)
            }).catch(error => {
                console.log(error)
            })
        };
        getSearchResults();
    }, [debouncedSearchTerm]);
    useEffect(() => {
        fetchCategories();
        fetchProducts();
        GetData();
    }, [currentPage, RowPerPage])
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/catlog/coupons/?ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Coupon Name",
            selector: "coupon_name",
            cell: row => row.coupon_name,
            sortable: true
        },
        {
            name: "Coupon Type",
            selector: "discount_type",
            cell: row => row.discount_type,
            sortable: true

        },
        {
            name: "Valid From",
            selector: "valid_from",
            cell: row => row.valid_from,
            sortable: true
        },
        {
            name: "Valid To",
            selector: "valid_to",
            cell: row => row.valid_to,
            sortable: true,
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    couponedit(row.id);
                    setModalTitle("Edit Coupon");
                };
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/catlog/coupons/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`/catlog/coupons/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Coupon revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {!row.is_suspended ?
                            <div className="row" >
                                <div className="col-4">
                                    <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>
                                <div className="col-4">
                                    <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                            </div>
                            :
                            <>
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            </>
                        }

                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }


    ]

    return (
        <>
            <div className="child-sidenav">
                <MarketingNavbarComponent />
                <div className="childnav-content">
                    <div className="cat-button">
                        <div className="btn" onClick={handleShow} >
                            + Add New
                        </div>
                    </div>
                    <div className="col-4 input-cat-with-icon">
                        <span className="icon-product"><BiSearch /></span>
                        <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                    </div>
                    <div className="table-responsive">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            {modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row align-items-center">
                                <p>Select the type of coupon you want to offer:</p>
                                <div className="row">
                                    <div className="icon-input">
                                        <div htmlFor="discount_type" name="discount_type" onClick={(e) => handleTypeChange(e, "discount_type", "Flat")} className={`square ${Active === "Flat" ? "selected" : ""}`}>
                                            <BsCurrencyRupee size={24} />
                                            <p>Discount</p>
                                        </div>
                                        <div htmlFor="discount_type" name="discount_type" onClick={(e) => handleTypeChange(e, "discount_type", "Percentage")} className={`square ${Active === "Percentage" ? "selected" : ""}`}>
                                            <BsPercent size={24} />
                                            <p>Discount</p>
                                        </div>
                                        <div htmlFor="discount_type" name="discount_type" onClick={(e) => handleTypeChange(e, "discount_type", "Free_Shipping")} className={` square ${Active === "Free_Shipping" ? "selected" : ""}`}>
                                            <TbTruckDelivery size={24} />
                                            <p>Free Shipping</p>
                                        </div>
                                        <div htmlFor="discount_type" name="discount_type" onClick={(e) => handleTypeChange(e, "discount_type", "Sale_Price")} className={` square ${Active === "Sale_Price" ? "selected" : ""}`}>
                                            <IoPricetagOutline size={24} />
                                            <p>Sale Price</p>
                                        </div>
                                        <div htmlFor="discount_type" name="discount_type" onClick={(e) => handleTypeChange(e, "discount_type", "Buy_X_get_Y_Free")} className={`square ${Active === "Buy_X_get_Y_Free" ? "selected" : ""}`}>
                                            <p>1+1</p>
                                            <p>Buy 'X' get 'Y' Free</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        {/* {Error} */}
                                        <div className="form-group">
                                            <label htmlFor="coupon_code">Coupon Code:</label>
                                            <input
                                                type="text"
                                                id="coupon_code"
                                                name="coupon_code"
                                                className="form-control"
                                                value={formData.coupon_code}
                                                onChange={(e) => handleFormChange(e)}
                                                required
                                            />
                                            <div className="Loginerror">{ValidateError.coupon_code}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group" onClick={HandleDefaultValue}>
                                            {/* {Error} */}
                                            <label htmlFor="coupon_name">Coupon Name:</label>
                                            <input
                                                type="text"
                                                id="coupon_name"
                                                name="coupon_name"
                                                className="form-control"
                                                value={formData.coupon_name}
                                                onChange={(e) => handleFormChange(e)}
                                                required
                                            />
                                            <div className="Loginerror">{ValidateError.coupon_name}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {Active === "Flat" && <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="discount"> Discount:</label>
                                            <input
                                                type="number"
                                                id="discount"
                                                name="discount"
                                                className="form-control"
                                                placeholder="₹"
                                                value={formData.discount}
                                                onChange={(e) => handleFormChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="Loginerror">{ValidateError.discount}</div>
                                    </div>}
                                    {Active === "Percentage" && <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="discount"> Discount:</label>
                                            <input
                                                type="number"
                                                id="discount"
                                                name="discount"
                                                className="form-control"
                                                placeholder="%"
                                                value={formData.discount}
                                                onChange={(e) => handleFormChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="Loginerror">{ValidateError.discount}</div>
                                    </div>}
                                    {Active === "Sale_Price" && <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="discount"> Now only:</label>
                                            <input
                                                type="number"
                                                id="now_only"
                                                name="now_only"
                                                className="form-control"
                                                placeholder="₹"
                                                value={formData.now_only}
                                                onChange={(e) => handleFormChange(e)}
                                            />
                                            <div className="Loginerror">{ValidateError.now_only}</div>
                                        </div>
                                    </div>}
                                    {Active === "Buy_X_get_Y_Free" && <div className="form-group">
                                        <label htmlFor="discount">Offer:</label>
                                        <div className="row">
                                            <div className="form-group col-5 d-flex align-items-center gap-4">
                                                <div>
                                                    <label htmlFor="buy">Buy</label>
                                                    {console.log(formData)}
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="buy"
                                                        value={formData.buy}
                                                        onChange={(e) => handleFormChange(e)}
                                                    />
                                                    <div className="Loginerror">{ValidateError.buy}</div>
                                                </div>
                                            </div>
                                            <div className="form-group col-5 d-flex align-items-center gap-4">
                                                <div>
                                                    <label htmlFor="get">Get</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="get"
                                                        value={formData.get}
                                                        onChange={(e) => handleFormChange(e)}
                                                    />
                                                    <div className="Loginerror">{ValidateError.get}</div>
                                                </div>
                                            </div>
                                            <div className="form-group col-2 d-flex align-items-center gap-4">
                                                <label htmlFor="get">Free</label>
                                            </div>

                                        </div>
                                    </div>}
                                </div>
                                <div className="row">
                                    {Active === "Flat" && <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="apply_to">Apply To:</label>
                                            <Select
                                                id="apply_to"
                                                name="apply_to"
                                                options={options}
                                                value={options.find(res => res.value === formData.apply_to)}
                                                onChange={(a) => handleSelectChange(a, "apply_to")}
                                                placeholder="Choose one"
                                                required
                                            />
                                            {console.log(validationErrors)}
                                            <div className="Loginerror">{ValidateError.apply_to}</div>
                                        </div>

                                    </div>}
                                    {Active === "Percentage" && <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="apply_to">Apply To:</label>
                                            <Select
                                                id="apply_to"
                                                name="apply_to"
                                                options={options}
                                                value={options.find(res => res.value === formData.apply_to)}
                                                onChange={(a) => handleSelectChange(a, "apply_to")}
                                                placeholder="Choose one"
                                                required
                                            />
                                            <div className="Loginerror">{ValidateError.apply_to}</div>
                                        </div>
                                    </div>}
                                    {Active === "Free_Shipping" && <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="apply_to">Apply To:</label>
                                            {console.log("applyto", formData)}
                                            <Select
                                                id="apply_to"
                                                name="apply_to"
                                                options={options1}
                                                value={options1.find(res => res.value === formData.apply_to)}
                                                onChange={(a) => handleSelectChange(a, "apply_to")}
                                                placeholder="Choose one"
                                                required
                                            />
                                            <div className="Loginerror">{ValidateError.apply_to}</div>
                                        </div>
                                    </div>}
                                    {Active === "Sale_Price" && <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="apply_to">Apply To:</label>
                                            <Select
                                                id="apply_to"
                                                name="apply_to"
                                                options={options2}
                                                value={options2.find(res => res.value === formData.apply_to)}
                                                onChange={(a) => handleSelectChange(a, "apply_to")}
                                                placeholder="Choose one"
                                                required
                                            />
                                            <div className="Loginerror">{ValidateError.apply_to}</div>
                                        </div>
                                    </div>}
                                    {Active === "Buy_X_get_Y_Free" && <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="apply_to">Apply To:</label>
                                            <Select
                                                id="apply_to"
                                                name="apply_to"
                                                options={options3}
                                                value={options3.find(res => res.value === formData.apply_to)}
                                                onChange={(a) => handleSelectChange(a, "apply_to")}
                                                placeholder="Choose one"
                                                required
                                            />
                                            <div className="Loginerror">{ValidateError.apply_to}</div>
                                        </div>
                                    </div>}
                                    {selectedValue === 'Specific_Product' && (
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="product">Product:</label>
                                                <Select
                                                    options={productOptions}
                                                    value={productOptions.find(res => res.value === formData.product)}
                                                    onChange={(selectedOption) => HandleDropdownChange(selectedOption, "product")}
                                                    placeholder="Search Product by name"
                                                />

                                            </div>
                                        </div>
                                    )}
                                    {selectedValue === 'Specific_Category' && (
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="category">Category:</label>
                                                <Select
                                                    options={categoryOptions}
                                                    value={categoryOptions.find(res => res.value === formData.category)}
                                                    onChange={(selectedOption) => HandleDropdownChange(selectedOption, "category")}
                                                    placeholder="Select category"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {selectedValue === 'Minimum_Order_Subtotal' && (
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label htmlFor="minimum_subtotal"> Minimum subtotal</label>
                                                {console.log("minimum modal inside", formData.minimum_subtotal)}
                                                <input
                                                    type="number"
                                                    id="minimum_subtotal"
                                                    name="minimum_subtotal"
                                                    className="form-control"
                                                    value={formData.minimum_subtotal}
                                                    onChange={(e) => handleFormChange(e)}
                                                    placeholder="₹"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {(Active === "Flat" || Active === "Percentage") && (selectedValue === "All_Product" || selectedValue === "Specific_Product" || selectedValue === "Specific_Category") &&
                                    <>
                                        <div className="d-flex align-items-center gap-4">
                                            <input
                                                className="radio-opoto"
                                                type="radio"
                                                id="apply_once_per_order"
                                                name="apply_once_per_order"
                                                checked={formData.apply_once_per_order}
                                                onChange={handleRadio}
                                            />
                                            <label htmlFor="ApplyOncePerOrder">Apply once per order (to the lowest priced item)</label>
                                        </div>
                                        <div className="d-flex align-items-center gap-4">
                                            <input
                                                className="radio-opoto"
                                                type="radio"
                                                id="apply_to_each_eligible_item_in_the_order"
                                                name="apply_to_each_eligible_item_in_the_order"
                                                checked={formData.apply_to_each_eligible_item_in_the_order}
                                                onChange={handleRadio}
                                            />
                                            <label htmlFor="limitOnePerCustomer">Limit to one use per customer</label>
                                        </div>
                                    </>
                                }
                                <hr className="horizontal_line" />
                                <div className="row align-items-center">
                                    <div className="col-12 ">
                                        <div className="form-group">
                                            <label> Valid Between:</label>
                                            <div className="row">
                                                <div className="form-group col-3">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="valid_from"
                                                        value={formData.valid_from}
                                                        onChange={(e) => handleFormChange(e)}
                                                    />
                                                </div>
                                                <div className="form-group col-3">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="valid_to"
                                                        value={formData.valid_to}
                                                        onChange={(e) => handleFormChange(e)}
                                                        disabled={disableValidTo}
                                                    />
                                                </div>
                                                <div className="col-6 d-flex align-items-center gap-4">

                                                    <input
                                                        className="checkbox-opto"
                                                        type="checkbox"
                                                        id="set"
                                                        checked={disableValidTo}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="limitUses">Don't set an end date</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {Error && <div className="Loginerror">
                                        <p>{Error}</p>
                                    </div>}
                                </div>
                                <div>
                                    <p>Limit Uses</p>
                                    <div>
                                        <div className="d-flex align-items-center gap-4">
                                            <input
                                                className="checkbox-opto"
                                                type="checkbox"
                                                id="limitUses"
                                                name="limit_the_total_number_of_uses_for_this_coupon"
                                                checked={formData.limit_the_total_number_of_uses_for_this_coupon}
                                                onChange={handleRadio}
                                            />
                                            <label htmlFor="limitUses">Limit the total number of uses for this coupon</label>
                                        </div>
                                        {formData.limit_the_total_number_of_uses_for_this_coupon && (
                                            <div>
                                                <input
                                                    type="number"
                                                    id="numberOfUses"
                                                    name="use_count"
                                                    className="form-control w-50"
                                                    value={formData.use_count}
                                                    onChange={(e) => handleFormChange(e)}
                                                />
                                            </div>
                                        )}
                                        <div className="d-flex align-items-center gap-4">
                                            <input
                                                className="checkbox-opto"
                                                type="checkbox"
                                                id="limitOnePerCustomer"
                                                name="limit_to_one_use_per_customer"
                                                checked={formData.limit_to_one_use_per_customer}
                                                onChange={handleRadio}
                                            />
                                            <label htmlFor="limitOnePerCustomer">Limit to one use per customer</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        {couponid ? (
                            <>
                                <button type="button" className="btn custom-submit-button" onClick={handleClose}>Cancel</button>
                                <button type="button" className="btn custom-submit-button" onClick={() => formSubmit(couponid)}>Save Changes</button>
                            </>
                        ) : (
                            <>
                                <button type="button" className="btn custom-submit-button" onClick={handleClose}>Cancel</button>
                                <button type="button" className="btn custom-submit-button" onClick={() => formSubmit(null)}>Create Coupon</button>
                            </>
                        )}

                    </Modal.Footer>
                </Modal>
                {popupType !== "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="/dashboard/masters/coupons" Message={message} />
                }
                {deletePopupType !== "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </div>
        </>
    )
}
export { Coupon }