import React, { useState } from 'react';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout';
import { CMSNavbarComponent } from '../navbar/CMSnavcomponent';
import { AiTwotoneSetting } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import { HiOutlineEye } from "react-icons/hi";
import { MdOutlineEdit, MdKeyboardArrowUp, MdKeyboardArrowDown, MdDelete } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const TextBlock = ({ id, setModuleButtons, moduleButtons }) => {
  const addNewModule = () => {
    const newModule = `Module ${moduleButtons.length + 1}`;
    setModuleButtons([...moduleButtons, newModule]);
  };
  const [content, setContent] = useState('');

  const handleChange = (value) => {
    setContent(value);
  };
  const handlesubmit = () => {
    const index = moduleButtons.findIndex(val => val.id === id)
    if (index !== -1) {
      let updatedData = [...moduleButtons]
      updatedData[index] = {
        ...updatedData[index],
        content: content,
      };
      console.log(updatedData)
      setModuleButtons([...updatedData]);
    }
  }
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  return (
    <div>
      <div className='text-block-end'>
        <div className='text-block-border'>
          <h5 className='dashboard-subtitle'>Text Block</h5>
          <div className='d-flex gap-4'>
            <AiTwotoneSetting color='#5C5C5C' />
            <CiCirclePlus />
            <HiOutlineEye />
            <MdOutlineEdit />
            <FiSave onClick={handlesubmit} />
            <MdKeyboardArrowUp />
            <MdKeyboardArrowDown />
            <MdDelete />
          </div>
        </div>
        <div className='p-2'>
          <ReactQuill modules={modules} value={content} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export { TextBlock };
