import React from "react";
import Select from "react-select";
import { MdOutlineEdit } from "react-icons/md";
import { ReactQuillComponent } from "./reactquill/reactquill";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdInfo } from "react-icons/md";
import { AddressModal } from "./AddressModal";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { FileManager } from "../FileManager/FileManager";
import { MultiFilemanager } from "./mutlipleImages";

const RenderFields = ({
  isRequired,
  fieldItem,
  FieldErrors,
  i,
  SetData,
  HandleChange,
  Data,
  hasError,
  readOnly,
  handleAutoSlug,
  loading1,
  HandleFiles,
  formatDate,
  HandleRadioChange,
  handleSelectChange,
  dynamicOptions,
  handleScroll,
  handleSearch,
  SelectDynamicOptions,
  handleMultiSelectChange,
  HandleMultiPulFiles,
  handleCheckboxChange,
  Handlefilemanager,
  RenderGroup,
  CheckboxChange,
  calculateMaxDateTime,
}) => {
  const getCurrentDateTime = (date) => {
    let currentDate;
    if (!date) {
      currentDate = new Date();
    } else {
      currentDate = new Date(date);
      // Increment the date by 1 day
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return currentDate.toISOString().slice(0, 16);
  };
  return (
    <div key={fieldItem.fieldName} className={fieldItem.groupClass}>
      {fieldItem.fieldType === "string" && (
        <div
          className={`form-group ${
            hasError || (FieldErrors[fieldItem.fieldName] && "has-error")
              ? "has-error"
              : ""
          }`}
        >
          <label htmlFor="validationCustom01">
            {fieldItem.label}{" "}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            {fieldItem.auto_slug ? (
              <input
                type="text"
                className={`form-control ${
                  hasError ||
                  (FieldErrors[fieldItem.fieldName] && "invalid-error")
                    ? "invalid-error"
                    : ""
                }`}
                id="validationCustom01"
                placeholder={fieldItem.label}
                name={fieldItem.fieldName}
                maxLength={fieldItem.max_length ? fieldItem.max_length : null}
                onChange={handleAutoSlug}
                defaultValue={Data[fieldItem.fieldName]}
                disabled={fieldItem.always_read_only ? true : readOnly}
              />
            ) : (
              <input
                type="text"
                className={`form-control ${
                  hasError ||
                  (FieldErrors[fieldItem.fieldName] && "invalid-error")
                    ? "invalid-error"
                    : ""
                }`}
                id="validationCustom01"
                placeholder={fieldItem.label}
                name={fieldItem.fieldName}
                maxLength={fieldItem.max_length ? fieldItem.max_length : null}
                onChange={HandleChange}
                defaultValue={Data[fieldItem.fieldName]}
                disabled={fieldItem.always_read_only ? true : readOnly}
              />
            )}
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "stringWithrightText" && (
        <div
          className={`form-group ${
            hasError || (FieldErrors[fieldItem.fieldName] && "has-error")
              ? "has-error"
              : ""
          }`}
        >
          <label htmlFor="validationCustom01">
            {fieldItem.add_label ? fieldItem.add_label : ""}-{fieldItem.label}{" "}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <input
              type="text"
              className={`form-control class-border custom-input${
                hasError ||
                (FieldErrors[fieldItem.fieldName] &&
                  "invalid-error class-border custom-input")
                  ? "invalid-error class-border custom-input"
                  : ""
              }`}
              id="validationCustom01"
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              maxLength={fieldItem.max_length ? fieldItem.max_length : null}
              onChange={HandleChange}
              defaultValue={Data[fieldItem.fieldName]}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            <div className="input-group-prepend">
              <span className="input-group-append-custom border-left-0">
                {fieldItem.right_text}
              </span>
            </div>
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "codewithbackground" && (
        <div
          className={`form-group ${
            hasError || (FieldErrors[fieldItem.fieldName] && "has-error")
              ? "has-error"
              : ""
          }`}
        >
          <label htmlFor="validationCustom01">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <div className="color-box">
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "5px",
                  backgroundColor: Data[fieldItem.fieldName],
                }}
              ></div>
            </div>
            <input
              type="text"
              className={`form-control custom-input-class custom-input${
                hasError ||
                (FieldErrors[fieldItem.fieldName] &&
                  "invalid-error class-border custom-input")
                  ? "invalid-error class-border custom-input"
                  : ""
              }`}
              id="validationCustom01"
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              maxLength={fieldItem.max_length ? fieldItem.max_length : null}
              onChange={HandleChange}
              defaultValue={Data[fieldItem.fieldName]}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "email" && (
        <div
          className={`form-group ${
            hasError || (FieldErrors[fieldItem.fieldName] && "has-error")
              ? "has-error"
              : ""
          }`}
        >
          <label htmlFor="validationCustom01">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <input
              type="email"
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              id="validationCustom01"
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              maxLength={fieldItem.max_length ? fieldItem.max_length : null}
              onChange={HandleChange}
              defaultValue={Data[fieldItem.fieldName]}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "url" && (
        <div
          className={`form-group ${
            hasError || (FieldErrors[fieldItem.fieldName] && "has-error")
              ? "has-error"
              : ""
          }`}
        >
          <label htmlFor="validationCustom01">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="nuput-box">
            <input
              type="url"
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              id="validationCustom01"
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              maxLength={fieldItem.max_length ? fieldItem.max_length : null}
              onChange={HandleChange}
              defaultValue={Data[fieldItem.fieldName]}
              required={fieldItem.required}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "boolean" && (
        <div className="form-group rowCenter" style={{ height: "100%" }}>
          <label className="form-check-label ari-radio-label">
            <input
              className="ari-radio"
              type="checkbox"
              name={fieldItem.fieldName}
              value="true"
              onChange={HandleChange}
              required={fieldItem.required}
              disabled={fieldItem.always_read_only ? true : readOnly}
              checked={Data[fieldItem.fieldName]}
              defaultChecked={fieldItem?.default_value}
            />{" "}
            {fieldItem.label}
          </label>
          {FieldErrors[fieldItem.fieldName] && (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-top">
                  Tooltip for <strong>{fieldItem.label}</strong>.
                </Tooltip>
              }
            >
              <button style={{ border: "none", background: "#FFF" }}>
                <MdInfo />
              </button>
            </OverlayTrigger>
          )}
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "charges" && (
        <div className="d-flex gap-4">
          <div className="form-group w-25">
            <label htmlFor="validationCustom01">
              Express<span className="required-star">{isRequired}</span>:
            </label>
            <label className="form-check-label ari-radio-label">
              <input
                className="ari-radio"
                type="checkbox"
                name={fieldItem.fieldName_1}
                value="true"
                onChange={HandleChange}
                required={fieldItem.required}
                disabled={readOnly}
                checked={Data[fieldItem.fieldName_1]}
              />
              {Data[fieldItem.fieldName_1] ? (
                <input
                  type="number"
                  min={0}
                  className="form-control"
                  name={fieldItem.fieldName_3}
                  onChange={HandleChange}
                  required
                  disabled={fieldItem.always_read_only ? true : readOnly}
                  value={Data[fieldItem.fieldName_3]}
                  style={{ height: "30px" }}
                />
              ) : (
                "Express"
              )}
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="validationCustom01">
              Schedule<span className="required-star">{isRequired}</span>:
            </label>
            <label className="form-check-label ari-radio-label">
              <input
                className="ari-radio"
                type="checkbox"
                name={fieldItem.fieldName_2}
                value="true"
                onChange={HandleChange}
                required={fieldItem.required}
                disabled={readOnly}
                checked={Data[fieldItem.fieldName_2]}
              />{" "}
              Schedule
            </label>
          </div>
        </div>
      )}
      {fieldItem.fieldType === "file" && (
        <div
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label>
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          {Data && Data[fieldItem.fieldName] && (
            <a href={Data[fieldItem.fieldName]} target="_blank">
              <img
                src={Data[fieldItem.fieldName]}
                width="30"
                className="form-edit-img"
              />
            </a>
          )}
          <div className="input-box">
            <input
              type="file"
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              onChange={HandleFiles}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}

      {fieldItem.fieldType === "date" && (
        <div
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label>
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <input
              type="date"
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              onChange={HandleChange}
              value={Data[fieldItem.fieldName]}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "datetime-local" && (
        <div className="form-group">
          <label>
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <input
              type="datetime-local"
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              placeholder={fieldItem.label}
              min={getCurrentDateTime(Data[fieldItem?.min_date_key])}
              name={fieldItem.fieldName}
              onChange={HandleChange}
              value={formatDate(Data[fieldItem.fieldName])}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "time" && (
        <div
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label>
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <input
              type="time"
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              onChange={HandleChange}
              defaultValue={Data[fieldItem.fieldName]}
              required={fieldItem.required}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "integer" && (
        <div
          className={`form-group ${
            hasError || (FieldErrors[fieldItem.fieldName] && "has-error")
              ? "has-error"
              : ""
          }`}
        >
          <label>
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <input
              type="number"
              min={0}
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              maxLength={fieldItem.max_length ? fieldItem.max_length : null}
              onChange={HandleChange}
              value={Data[fieldItem.fieldName]}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "disable_integer" &&
        Data[fieldItem?.displayKey] === fieldItem?.displayValue && (
          <div
            className={`form-group ${
              hasError || (FieldErrors[fieldItem.fieldName] && "has-error")
                ? "has-error"
                : ""
            }`}
            style={{
              display: fieldItem.switch_dependency
                ? Data[fieldItem.switch_dependency]
                  ? "block"
                  : "none"
                : "",
            }}
          >
            <label>
              {fieldItem.label}
              <span className="required-star">{isRequired}</span>:
            </label>
            <div className="input-box">
              <input
                type={fieldItem.type}
                className={`form-control ${
                  hasError ||
                  (FieldErrors[fieldItem.fieldName] && "invalid-error")
                    ? "invalid-error"
                    : ""
                }`}
                placeholder={fieldItem.label}
                name={fieldItem.fieldName}
                maxLength={fieldItem.max_length ? fieldItem.max_length : null}
                onChange={HandleChange}
                value={Data[fieldItem.fieldName]}
                disabled={fieldItem.always_read_only ? true : readOnly}
              />
              {FieldErrors[fieldItem.fieldName] && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Tooltip for <strong>{fieldItem.label}</strong>.
                    </Tooltip>
                  }
                >
                  <button style={{ border: "none", background: "#FFF" }}>
                    <MdInfo />
                  </button>
                </OverlayTrigger>
              )}
            </div>
            {FieldErrors[fieldItem.fieldName] && (
              <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
            )}
          </div>
        )}
      {fieldItem.fieldType === "password" && (
        <div
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label>
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <input
              type="password"
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              maxLength={fieldItem.max_length ? fieldItem.max_length : null}
              onChange={HandleChange}
              defaultValue={Data[fieldItem.fieldName]}
              disabled={fieldItem.always_read_only ? true : readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "radio" && (
        <div
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label>
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <br />
          {fieldItem.options &&
            fieldItem.options.map((optionItem, index) => {
              return (
                <div className="form-check-inline" key={index}>
                  <label className="form-check-label ari-radio-label">
                    <input
                      type="radio"
                      className="ari-radio"
                      name={fieldItem.fieldName}
                      checked={Data[fieldItem.fieldName] === optionItem}
                      defaultValue={Data[fieldItem.fieldName]}
                      onChange={(e) => HandleRadioChange(e, optionItem)}
                      required={fieldItem.required}
                      disabled={fieldItem.always_read_only ? true : readOnly}
                    />
                    {optionItem}
                  </label>
                </div>
              );
            })}
          {/* {fieldItem.required && (
                                                        <OverlayTrigger
                                                        placement="right"
                                                        overlay={
                                                          <Tooltip id="tooltip-top">
                                                            Tooltip for <strong>{fieldItem.label}</strong>.
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <button style={{border:"none",background:"#FFF"}}>
                                                        <MdInfo />
                                                        </button>
                                                      </OverlayTrigger>
                                                    )} */}
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "textarea" && (
        <div
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label>
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <textarea
              className={`form-control ${
                hasError ||
                (FieldErrors[fieldItem.fieldName] && "invalid-error")
                  ? "invalid-error"
                  : ""
              }`}
              rows="2"
              placeholder={fieldItem.label}
              name={fieldItem.fieldName}
              onChange={HandleChange}
              defaultValue={Data[fieldItem.fieldName]}
              disabled={fieldItem.always_read_only ? true : readOnly}
            ></textarea>
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}

      {fieldItem.fieldType === "dropdown" && (
        <div
          key={i}
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label className="form-label">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <div>
              <Select
                className={
                  FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""
                }
                options={fieldItem.options}
                placeholder={fieldItem.label}
                value={fieldItem.options.find(
                  (res) => res.value === Data[fieldItem.fieldName]
                )}
                defaultValue={fieldItem.options.find(
                  (res) => res.value === fieldItem?.default_value
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, fieldItem.fieldName)
                }
                isDisabled={fieldItem.always_read_only ? true : readOnly}
                isClearable={true}
              />
            </div>
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "dynamicdropdown" && (
        <div
          key={i}
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label className="form-label">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          {/* <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange}>
                                                        <option value="">SELECT {fieldItem.label}</option>
                                                        {
                                                            dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((optionItem, i) => {
                                                                return (
                                                                    <option value={optionItem.id} key={i} selected={optionItem.id === Data[fieldItem.fieldName]}>{optionItem[fieldItem.displayField]}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>  */}
          <div className="input-box">
            <div>
              <Select
                className={
                  FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""
                }
                options={
                  dynamicOptions[fieldItem.fieldName] &&
                  dynamicOptions[fieldItem.fieldName].map((res) => ({
                    value: res.id,
                    label: res[fieldItem.displayField],
                  }))
                }
                placeholder={fieldItem.label}
                onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                isLoading={loading1}
                onInputChange={(e) => handleSearch(e, fieldItem.fieldName)}
                value={
                  dynamicOptions[fieldItem.fieldName] &&
                  dynamicOptions[fieldItem.fieldName]
                    .map((res) => ({
                      value: res.id,
                      label: res[fieldItem.displayField],
                    }))
                    .find((res) => res.value === Data[fieldItem.fieldName])
                }
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, fieldItem.fieldName)
                }
                isDisabled={fieldItem.always_read_only ? true : readOnly}
                isClearable={true}
                autoComplete="off"
              />
            </div>
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}

      {fieldItem.fieldType === "dynamicSelectdropdown" && (
        <div
          key={i}
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label className="form-label">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          {console.log(Data[fieldItem.fieldName])}
          <div className="input-box">
            <Select
              className={
                FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""
              }
              options={
                SelectDynamicOptions[fieldItem.fieldName] &&
                SelectDynamicOptions[fieldItem.fieldName].map((res) => ({
                  value: res.id,
                  label: res[fieldItem.displayField],
                }))
              }
              placeholder={fieldItem.label}
              onMenuScrollToBottom={() =>
                handleScroll(fieldItem.fieldName, fieldItem.fieldType)
              }
              isLoading={loading1}
              onInputChange={(e) => handleSearch(e, fieldItem.fieldName)}
              value={
                Data[fieldItem.fieldName]
                  ? SelectDynamicOptions[fieldItem.fieldName] &&
                    SelectDynamicOptions[fieldItem.fieldName]
                      .map((res) => ({
                        value: res.id,
                        label: res[fieldItem.displayField],
                      }))
                      .find((find) => find.value === Data[fieldItem.fieldName])
                  : null
              }
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, fieldItem.fieldName)
              }
              isDisabled={fieldItem.always_read_only ? true : readOnly}
              isClearable={true}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "dynamicMultiSelectdropdown" && (
        <div
          key={i}
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label className="form-label">{fieldItem.label}:</label>
          <Select
            isMulti
            options={
              dynamicOptions[fieldItem.fieldName] &&
              dynamicOptions[fieldItem.fieldName].map((res) => ({
                value: res.id,
                label: res[fieldItem.displayField],
              }))
            }
            placeholder={fieldItem.label}
            value={
              dynamicOptions[fieldItem.fieldName] &&
              dynamicOptions[fieldItem.fieldName]
                .map((res) => ({
                  value: res.id,
                  label: res[fieldItem.displayField],
                }))
                .filter((option) =>
                  (Data[fieldItem.fieldName] || []).includes(option.value)
                )
            }
            onChange={(selectedOption) =>
              handleMultiSelectChange(
                selectedOption,
                selectedOption.value,
                fieldItem.fieldName
              )
            }
            required={fieldItem.required}
          />
        </div>
      )}

      {/* {
                                            fieldItem.fieldType === "DynamicSelectOptions" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}:</label>
                                                <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange}>
                                                    <option value="">SELECT {fieldItem.label}</option>
                                                    {
                                                       SelectDynamicOptions.map((optionItem, i) => {
                                                            return (
                                                                <option value={optionItem.id} key={i} selected={optionItem.id===Data[fieldItem.fieldName]?Data[fieldItem.fieldName]:undefined} >{optionItem[fieldItem.displayField]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        } */}
      {fieldItem.fieldType === "mulitipulFiles" && (
        <div
          key={i}
          className="form-group"
          style={{
            display: fieldItem.switch_dependency
              ? Data[fieldItem.switch_dependency]
                ? "block"
                : "none"
              : "",
          }}
        >
          <label className="form-label">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <input
            className="form-control"
            type="file"
            id="formFileMultiple"
            name={fieldItem.fieldName}
            multiple
            onChange={HandleMultiPulFiles}
            required={fieldItem.required}
            disabled={fieldItem.always_read_only ? true : readOnly}
          />
        </div>
      )}
      {fieldItem.fieldType === "switch" && (
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            style={{ width: "36px", height: "20px" }}
            type="checkbox"
            role="switch"
            id={fieldItem.fieldName}
            name={fieldItem.fieldName}
            checked={Data[fieldItem.fieldName]}
            onChange={handleCheckboxChange}
            disabled={fieldItem.always_read_only ? true : readOnly}
          />
          <label
            class="form-check-label"
            htmlFor={fieldItem.fieldName}
            style={{ marginLeft: "1.5em" }}
          >
            {fieldItem.label}
          </label>
        </div>
      )}

      {fieldItem.fieldType === "ReactQuill" && (
        <div className="form-group">
          <label className="form-label">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>
          <div className="input-box">
            <ReactQuillComponent
              className={
                FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""
              }
              SetData={SetData}
              Data={Data}
              name={fieldItem.fieldName}
              value={Data[fieldItem.fieldName]}
              required={fieldItem.required}
              readOnly={readOnly}
            />
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "subheading" && (
        <div className="form-group">
          {console.log(Data[fieldItem.fieldName])}
          <h6>{fieldItem.label}</h6>
        </div>
      )}
      {fieldItem.fieldType === "map" && (
        <div className="form-group">
          <AddressModal
            setFormData={SetData}
            formData={Data}
            readOnly={readOnly}
          />
        </div>
      )}
      {fieldItem.fieldType === "group" && (
        <div className="form-group">
          {console.log(fieldItem, "group")}
          {RenderGroup(fieldItem, i)}
        </div>
      )}
      {fieldItem.fieldType === "filemanager" && (
        <div
          className={`form-group ${
            hasError || (FieldErrors[fieldItem.fieldName] && "has-error")
              ? "has-error"
              : ""
          }`}
        >
          <label className="form-label">
            {fieldItem.label}
            <span className="required-star">{isRequired}</span>:
          </label>

          <div className="banner-image">
            <div
              className={`image-field ${
                hasError || (FieldErrors[fieldItem.fieldName] && "active")
                  ? "active"
                  : ""
              }`}
              style={{ width: "100%" }}
            >
              <label
                className="image-container"
                htmlFor="imageInput"
                onClick={
                  !readOnly
                    ? () => Handlefilemanager(fieldItem.fieldName)
                    : null
                }
              >
                {Data[fieldItem.fieldName] ? (
                  <img
                    className="image"
                    src={
                      Data[fieldItem.fieldName] &&
                      Data[fieldItem.fieldName]["image"]
                    }
                    alt={Data[fieldItem.fieldName]}
                  />
                ) : (
                  <label htmlFor="imageInput" className="image-icon">
                    <BsFillCloudUploadFill className="upload-icon" />
                    <p className="textfile">Choose a file</p>
                  </label>
                )}
              </label>
            </div>
            {FieldErrors[fieldItem.fieldName] && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-top">
                    Tooltip for <strong>{fieldItem.label}</strong>.
                  </Tooltip>
                }
              >
                <button style={{ border: "none", background: "#FFF" }}>
                  <MdInfo />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {FieldErrors[fieldItem.fieldName] && (
            <div className="invalid">{FieldErrors[fieldItem.fieldName]}</div>
          )}
        </div>
      )}
      {fieldItem.fieldType === "multifilemanager" && (
        <MultiFilemanager
          formData={Data}
          setFormData={SetData}
          Handlefilemanager={Handlefilemanager}
        />
      )}
      {fieldItem.fieldType === "color" && (
        <div className="form-group form-group-color">
          <label className="form-label">{fieldItem.label}</label>
          <input
            type="color"
            className="color-input"
            name={fieldItem.fieldName}
            value={Data[fieldItem.fieldName]}
            onChange={(e) =>
              SetData({ ...Data, [fieldItem.fieldName]: e.target.value })
            }
            disabled={readOnly}
          />
        </div>
      )}
    </div>
  );
};
export default RenderFields;
