import React from "react";
import { NavLink } from "react-router-dom";
import { MdHistory, MdOutlineDateRange } from "react-icons/md";
import { AiOutlineBarChart, AiOutlineLineChart, AiOutlinePieChart } from "react-icons/ai";
import { GoDesktopDownload } from "react-icons/go";
const ReportsNav = () => {
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/reports" className="categorie-subtitle" ><div className="child-title">
                <MdHistory size={24} className="childnav-icon" />
                <p className="childnav-name">Orders History</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/consolidated_reports" className="categorie-subtitle" ><div className="child-title">
                <GoDesktopDownload size={24} className="childnav-icon" />
                <p className="childnav-name">Consolidated</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/item_user_reports" className="categorie-subtitle" ><div className="child-title">
                <AiOutlineBarChart size={24} className="childnav-icon" />
                <p className="childnav-name">Item & User Reports</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/client_wise_sales" className="categorie-subtitle" ><div className="child-title">
                <AiOutlineLineChart size={24} className="childnav-icon" />
                <p className="childnav-name">User-wise Sales</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/item_wise_sales" className="categorie-subtitle" ><div className="child-title">
                <AiOutlinePieChart size={24} className="childnav-icon" />
                <p className="childnav-name">Item-wise Sales</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/date_wise_sales" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineDateRange size={24} className="childnav-icon" />
                <p className="childnav-name">Date-wise Sales</p>
            </div></NavLink>
        </div>
    )
}

export {ReportsNav} 